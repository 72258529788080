// import Swiper from 'swiper';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
// configure Swiper to use modules
import $ from "jquery";
import datetimepicker from "jquery-datetimepicker";
import sal from 'sal.js';
import { uaCheck } from "./modules/utils";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
uaCheck();

let entryY = 0;

const mq = (device) => {
    if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
        return true;
    }
    return window.matchMedia('(min-width:769px)').matches && device === 'pc';
};

// ページトップへスクロール
const goToPageTop = (val) => {
    $(val).on('click', () => {
        $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
        return false;
    });
};

// スムーススクロール
const smoothScroll = (val) => {
    $(val).on('click', (e) => {
        const speed = 500;
        let adjust = 0;
        if (mq('pc')) {
            adjust = 130;
        } else {
            adjust = 100;
        }
        const href = $(e.currentTarget).attr('href');
        const target = $(href === '#' || href === '' ? 'html' : href);
        const position = target.offset().top - adjust;
        console.log(position);
        $('html, body').animate({ scrollTop: position }, speed, 'swing');
        return false;
    });
};

const topRecommendedSlider = () => {
    const swiper = new Swiper(".top-recommended-slider", {
         autoplay: {
            delay: 2000,
        },
        loop: true,
        speed: 2000,
        effect: "fade",
        pagination: {
            el: ".swiper-pagination",
        },
    });
}


const handleFormHeightIframe = (elementName) =>{
  const $iframe = $(elementName);
  console.log($iframe);

  setTimeout(() => {
    console.log($iframe.contents().find('html').innerHeight());
    $iframe.height($iframe.contents().find('html').innerHeight());
  }, 300);
}

// Menu UI Functions
const menuToggleFunc = () => {
    const mTrigger = document.getElementById("menu-trigger");


    mTrigger.addEventListener("click", () => {
      if ($('body').hasClass('menu-show')) {
        $('body').removeClass('menu-show');
      } else {
        $('body').addClass('menu-show');
      }
    });
  };


  const formCheck = () => {
    // エラーを表示する関数（error クラスの p 要素を追加して表示）
    function showError(message, this$) {
      let text = this$.parent().find("label").text() + message;
      this$.parent().append(`<p class='error'>${  text  }</p>`);
    }

    // フォームが送信される際のイベントハンドラの設定
    // eslint-disable-next-line consistent-return
    $("#formPage1").submit( () => {
      // エラー表示の初期化
      $("p.error").remove();
      $("div").removeClass("error");
      let text = "";
      $("#errorDispaly").remove();

      // メールアドレスの検証
      let email = $.trim($("#email").val());
      if (
        email &&
        // eslint-disable-next-line no-useless-escape
        !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
          email
        )
      ) {
        $("#email").after(
          "<p class='error'>メールアドレスの形式が異なります</p>"
        );
      }
      // 確認用メールアドレスの検証
      // var email_check =  $.trim($("#email_check").val());
      // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
      //   showError("が異なります", $("#email_check"));
      // }

      // 電話番号の検証
      let tel = $.trim($("#tel").val());
      if (
        tel &&
        // eslint-disable-next-line no-useless-escape
        !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
      ) {
        $("#tel").after(
          "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
        );
      }

      // カナの検証
      let kana = $.trim($("#kana").val());
      if (kana && !kana.match(/^[ァ-ヶー]+$/)) {
        $("#kana").after(
          "<p class='error'>全角カタカナではない文字が含まれています</p>"
        );
      }

      // 1行テキスト入力フォームとテキストエリアの検証
      $(":text,textarea")
        .filter(".validate")
        // eslint-disable-next-line func-names
        .each( function () {
          // 必須項目の検証
          $(this)
            .filter(".required")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val() === "") {
                showError("必須項目です", $(this));
              }
            });
          // 文字数の検証
          $(this)
            .filter(".max30")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 30) {
                showError(" は30文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max50")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 50) {
                showError(" は50文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max100")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 100) {
                showError(" は100文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max1000")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 1000) {
                showError(" は1000文字以内でお願いします", $(this));
              }
            });
        });

      // error クラスの追加の処理
      if ($("p.error").length > 0) {
        $("p.error").parent().addClass("error");
        $("html,body").animate(
          { scrollTop: $("p.error:first").offset().top - 180 },
          "slow"
        );
        const $iframe = window.parent.document.getElementById("parentForm1");
        const $topContact = window.parent.document.getElementById("topContact");

        setTimeout(() => {
          $iframe.style.height = `${document.body.clientHeight + 50}px`;
          window.parent.scrollTo(0, sessionStorage.getItem("topContactY"));
        }, 100);
        return false;
      }
    });


  };

  const formCheck2 = () => {
    // function disableSundays(date) {
    //   return [date.getDay() !== 0, ''];
    // }

    // $.datetimepicker.setLocale('ja');
    // $('#kibounichiji').datetimepicker({
    //   beforeShowDay: disableSundays,
    //   format: 'H:i',
    //   minTime: '10:00', // 10時以降の時間のみ選択可能
    //   maxTime: '18:00', // 18時までの時間のみ選択可能
    // });

    // エラーを表示する関数（error クラスの p 要素を追加して表示）
    function showError(message, this$) {
      let text = this$.parent().find("label").text() + message;
      this$.parent().append(`<p class='error'>${  text  }</p>`);
    }

    // フォームが送信される際のイベントハンドラの設定
    // eslint-disable-next-line consistent-return
    $("#formPage2").submit( () => {
      let isRadioCheck = false;

      console.log($('input[name=radio01]').filter(':checked').length);



      // エラー表示の初期化
      $("p.error").remove();
      $("div").removeClass("error");
      let text = "";
      $("#errorDispaly").remove();

      // メールアドレスの検証
      let email = $.trim($("#email").val());
      if (
        email &&
        // eslint-disable-next-line no-useless-escape
        !/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi.test(
          email
        )
      ) {
        $("#email").after(
          "<p class='error'>メールアドレスの形式が異なります</p>"
        );
      }
      // 確認用メールアドレスの検証
      // var email_check =  $.trim($("#email_check").val());
      // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
      //   showError("が異なります", $("#email_check"));
      // }

      // 電話番号の検証
      let tel = $.trim($("#tel").val());
      if (
        tel &&
        // eslint-disable-next-line no-useless-escape
        !/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi.test(tel)
      ) {
        $("#tel").after(
          "<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>"
        );
      }

      // カナの検証
      let kana = $.trim($("#kana").val());
      if (kana && !kana.match(/^[ァ-ヶー]+$/)) {
        $("#kana").after(
          "<p class='error'>全角カタカナではない文字が含まれています</p>"
        );
      }

      // 1行テキスト入力フォームとテキストエリアの検証
      $(":text,textarea")
        .filter(".validate")
        // eslint-disable-next-line func-names
        .each( function () {
          // 必須項目の検証
          $(this)
            .filter(".required")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val() === "") {
                showError("必須項目です", $(this));
              }
            });
          // 文字数の検証
          $(this)
            .filter(".max30")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 30) {
                showError(" は30文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max50")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 50) {
                showError(" は50文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max100")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 100) {
                showError(" は100文字以内です", $(this));
              }
            });
          $(this)
            .filter(".max1000")
            // eslint-disable-next-line func-names
            .each(function () {
              if ($(this).val().length > 1000) {
                showError(" は1000文字以内でお願いします", $(this));
              }
            });
        });
        if($('input[name=radio01]').filter(':checked').length === 0) {
          // $(".error-radio01").parent().addClass("error");
          $('.error-radio01').text("必須項目です");
        }else{
          $('.error-radio01').remove();
        }
      // error クラスの追加の処理
      if ($("p.error").length > 0) {
        $("p.error").parent().addClass("error");

        setTimeout(() => {
          window.parent.scrollTo(0, sessionStorage.getItem("entryFormY"));

        }, 100);
        return false;

      }


    });


  };

  const topNewsSlider = () => {
    const swiper = new Swiper('.top-news-slider', {
        slidesPerView: 2,
        breakpoints: {
            768: {
                slidesPerView: 4,
            }
        },
        // loop: true,
        spaceBetween: 30,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        centeredSlides: false,
    });
}

const imageGallery = () =>{
  const images = document.querySelectorAll(".js-gallery-item img");
  const modalWrap = document.querySelector(".modal-wrap");

  let imgIndex
  let imgSrc;

  images.forEach((img, i) => {

    img.addEventListener("click", (e) => {
      modalWrap.classList.add("show");
      imgSrc = e.target.dataset.target;

      // eslint-disable-next-line no-use-before-define
      imgModal(imgSrc);
      imgIndex = i;
    });

  });

  let imgModal = (src) => {
    const appendDiv = ".js-gallery";

      const modal = document.createElement("div");

      modal.setAttribute("class", "modal");


      document.querySelector(appendDiv).append(modal);
      const newImage = document.createElement("img");
      newImage.setAttribute("src", src);
      const closeBtn = document.createElement("i");

      closeBtn.setAttribute("class", "fas fa-times closeBtn");
      closeBtn.onclick = () => {

      modal.remove();
      modalWrap.classList.remove("show");

  };




  const nextBtn = document.createElement("i");
  nextBtn.setAttribute("class", "fas fa-angle-right nextBtn");

  let nextImg = () => {

    imgIndex += 1;
    if (imgIndex >= images.length) {
        imgIndex = 0
    }
    return images[imgIndex].dataset.target;
  };

  nextBtn.onclick = () => {
      newImage.setAttribute("src", nextImg())
  };
  const prevBtn = document.createElement("i");
  prevBtn.setAttribute("class", "fas fa-angle-left prevBtn");
  let prevImg = () => {
    imgIndex -= 1;
    if (imgIndex < 0) {
        imgIndex = images.length - 1
    }
    return images[imgIndex].dataset.target
}
  prevBtn.onclick = () => {

      newImage.setAttribute("src", prevImg())
  }
  modal.append(newImage, closeBtn, nextBtn, prevBtn);
  };

}

window.addEventListener('DOMContentLoaded', (event) => {
    menuToggleFunc();
    sal();
    if (document.getElementById("topPage")) {
      topNewsSlider();
    }
    if (document.getElementById("parentForm1")) {

        sessionStorage.setItem("topContactY", document.getElementById("topContact").getBoundingClientRect().top + window.pageYOffset);
        handleFormHeightIframe("#parentForm1");
    }
    if (document.getElementById("formPage1")) {
        formCheck();
    }
    if($("#entryFormArea").length){
      sessionStorage.setItem("entryFormY", document.getElementById("entryFormArea").getBoundingClientRect().top + window.pageYOffset);
      handleFormHeightIframe("#parentForm2");

    }
    if ($("#formPage2").length) {
      formCheck2();
    }

    if ($("#contactConfirm").length || $("#entryComplete").length ) {
      window.parent.scrollTo(0, sessionStorage.getItem("topContactY"));
    }

    if ($("#entryConfirm").length || $("#entryComplete").length ) {
      window.parent.scrollTo(0, sessionStorage.getItem("entryFormY"));
    }

    if ($("#worksDetail").length) {
      // imageGallery();
    }

});
